import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#006BF5',
        grey_background: '#F1F1F1',
        chip_background: '#D9DBDC',
        border_color: '#DEDEDE',
        dark_grey: '#2C3039',
        button_grey: '#E4E5E6',
      },
    },
  },
});
