import axios from 'axios';

export async function getAccessToken(verifier, authCode) {
  return axios.post(
    `${process.env.VUE_APP_KEYCLOAK_URL}/protocol/openid-connect/token`,
    new URLSearchParams({
      grant_type: 'authorization_code',
      redirect_uri: `${window.location.origin}${process.env.VUE_APP_KEYCLOAK_REDIRECT_PATH}`,
      client_id: 'nsoft-hub',
      code: authCode,
      code_verifier: verifier,
    }).toString(),
  );
}

export async function refreshAccessToken(refreshToken) {
  return axios.post(
    `${process.env.VUE_APP_KEYCLOAK_URL}/protocol/openid-connect/token`,
    new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: 'nsoft-hub',
    }).toString(),
  );
}

export default {
  userInfo(jwt) {
    return axios.get(`${process.env.VUE_APP_KEYCLOAK_URL}/protocol/openid-connect/userinfo`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
  },
};
