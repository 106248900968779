import { getItems, getItemsById } from '../../../api/index';
import rootTypes from '../../mutationTypes';
import types from './mutationTypes';

export default {
  async getProducts({ commit }) {
    commit(types.SET_LOADING, false);
    commit(types.SET_PRODUCTS, await getItems('products'));
    commit(types.SET_LOADING, true);
  },
  async getProductById({ state, commit }, id) {
    const currentProduct = await getItemsById('products', id);
    const similarProducts = state.products.filter((p) => p.group === currentProduct.group
                                                    && p.name !== currentProduct.name);
    commit(types.SET_SIMILAR_PRODUCT, similarProducts);
    commit(types.SET_CURRENT_PRODUCT, currentProduct);
    commit(rootTypes.SET_ASSETS, 'all', { root: true });
  },
  setSimilarProduct({ state, commit }) {
    const similarProducts = state.products.filter((p) => p.group === state.product.group
                                                    && p.name !== state.product.name);
    commit(types.SET_SIMILAR_PRODUCT, similarProducts);
  },
  updateProductInProductList({ state, commit }, product) {
    const productList = state.products;
    const match = productList.find((p) => p._id === product._id);
    if (JSON.stringify(match) !== JSON.stringify(product)) {
      productList[productList.indexOf(match)] = product;
      commit(types.SET_PRODUCTS, []);
      commit(types.SET_PRODUCTS, productList);
    }
  },
  resetCurrentProduct({ commit }) {
    commit(types.SET_CURRENT_PRODUCT, {});
  },
};
