import {
  uniq, each, some, union,
} from 'lodash';

export default {
  allProducts: (state, getters, rootState) => {
    const filterProducts = state.products.filter((i) => {
      // Filter by Type
      let type = rootState.filterType.includes(i.type);
      type = (rootState.filterType.length > 0) ? type : true;
      // Filter by Category
      let category = rootState.filterCategory.includes(i.category);
      category = (rootState.filterCategory.length > 0) ? category : true;
      // Filter by Brand
      let brand = rootState.filterBrand.includes(i.brand);
      brand = (rootState.filterBrand.length > 0) ? brand : true;
      // Filter by Manufacturer
      let manufacturer = rootState.filterManufacturer.includes(i.manufacturer);
      manufacturer = (rootState.filterManufacturer.length > 0) ? manufacturer : true;
      // Filter by Channel
      let channel = false;
      each(i.channels, (platformChannel) => {
        if (rootState.filterChannel.includes(platformChannel.name) === true) {
          channel = true;
        }
      });
      channel = (rootState.filterChannel.length > 0) ? channel : true;
      // Filter by Unpublished
      const publishStatus = rootState.showUnpublished ? true : i.published;
      // return logical AND of those variables
      return type && category && brand && manufacturer && channel && publishStatus;
    });
    if (rootState.search.length > 0) {
      const qr = rootState.search.toLowerCase();
      return filterProducts.filter((i) => i.name.toLowerCase().includes(qr)
      || some(i.keywords, (k) => k.toLowerCase().includes(qr)));
    }
    return filterProducts;
  },
  productCount: (state, getters) => getters.allProducts.length,
  currentProduct: (state) => state.product,
  similarProducts: (state) => state.productSimilar,
  productTypes: (state) => [...new Set(state.products.map((product) => product.type))],
  productCategories: (state) => [...new Set(state.products.map((product) => product.category))],
  productBrands: (state) => [...new Set(state.products.map((product) => product.brand))],
  productManufacturers: (state) => [...new Set(state.products.map((prod) => prod.manufacturer))],
  productGroups: (state) => [...new Set(state.products.map((product) => product.group))],
  productProviders: (state) => [...new Set(state.products.map((product) => product.provider))],
  productPlatforms: (state) => [...new Set(state.products.map((product) => product.platform))],
  productChannels: (state) => {
    const channels = [];
    each(state.products, (product) => {
      each(product.channels, (channel) => {
        const match = channels.find((e) => e.name === channel.name);
        if (match) {
          match.applications = uniq(match.applications, channel.applications);
        } else {
          channels.push(channel);
        }
      });
    });
    return channels;
  },
  productKeywords: (state) => union(...state.products.map((product) => product.keywords)),
  assetTypes: (state) => [...new Set(state.product.assets.map((img) => `${img.assetType}s`))],
  assetImages: (state, getters, rootState) => {
    if (rootState.filterAssets.length > 0) {
      if (rootState.filterAssets === 'all') {
        return state.product.assets;
      }
      const images = state.product.assets;
      return images.filter((i) => rootState.filterAssets.includes(i.assetType));
    }
    return state.product.assets;
  },
  isLoaded: (state) => state.loading,
};
