import types from './mutationTypes';

export default {
  [types.SET_PRODUCTS](state, payload) {
    state.products = payload;
  },
  [types.SET_CURRENT_PRODUCT](state, payload) {
    state.product = payload;
  },
  [types.SET_SIMILAR_PRODUCT](state, payload) {
    state.productSimilar = payload;
  },
  [types.SET_LOADING](state, payload) {
    state.loading = payload;
  },
};
