export default {
  allApis: (state, getters, rootState) => {
    const filterApis = state.apis.filter((i) => {
      // Filter by Unit
      let unit = rootState.filterUnit.includes(i.unit);
      unit = (rootState.filterUnit.length > 0) ? unit : true;
      // Filter by Unpublished
      const publishStatus = rootState.showUnpublished ? true : i.published;
      return unit && publishStatus;
    });
    if (rootState.search.length > 0) {
      const qr = rootState.search.toLowerCase();
      return filterApis.filter((i) => i.name.toLowerCase().includes(qr));
    }
    return filterApis;
  },
  apiCount: (state, getters) => getters.allApis.length,
  currentApi: (state) => state.api,
  apiUnits: (state) => [...new Set(state.apis.map((api) => api.unit))],
  isLoaded: (state) => state.loading,
};
