import types from './mutationTypes';

export default {
  [types.SET_SEARCH](state, payload) {
    state.search = payload;
  },
  [types.SET_TYPE](state, payload) {
    state.filterType = payload;
  },
  [types.SET_CATEGORY](state, payload) {
    state.filterCategory = payload;
  },
  [types.SET_BRAND](state, payload) {
    state.filterBrand = payload;
  },
  [types.SET_MANUFACTURER](state, payload) {
    state.filterManufacturer = payload;
  },
  [types.SET_CHANNEL](state, payload) {
    state.filterChannel = payload;
  },
  [types.SET_ASSETS](state, payload) {
    state.filterAssets = payload;
  },
  [types.SET_UNIT](state, payload) {
    state.filterUnit = payload;
  },
  [types.SET_SHOW_UNPUBLISHED](state, payload) {
    state.showUnpublished = payload;
  },
  [types.SET_EDIT_MODE](state, payload) {
    state.editMode = payload;
  },
  [types.SET_UNSAVED_CHANGES](state, payload) {
    state.unsavedChanges = payload;
  },
  [types.SET_CONFIRMATION](state, payload) {
    state.confirmation = payload;
  },
  [types.SET_WARNING](state, payload) {
    state.warning = payload;
  },
  [types.SET_WARNING_CONTEXT](state, payload) {
    state.warningContext = payload;
  },
  [types.SET_SNACKBAR](state, payload) {
    state.snackbar = payload;
  },
  [types.SET_SNACKBAR_TIMEOUT](state, payload) {
    state.snackbarTimeout = payload;
  },
  [types.SET_SNACKBAR_CONTEXT](state, payload) {
    state.snackbarContext = payload;
  },
};
