export default {
  search: {},
  filterType: [],
  filterCategory: [],
  filterBrand: [],
  filterManufacturer: [],
  filterChannel: [],
  filterAssets: {},
  filterUnit: [],
  showUnpublished: true,
  editMode: false,
  unsavedChanges: false,
  warning: false,
  warningContext: { text: '', color: '' },
  snackbar: false,
  snackbarTimeout: null,
  snackbarContext: { text: '', color: 'primary' },
  confirmation: null,
};
