import { jwtDecode } from 'jwt-decode';
import sha256 from 'crypto-js/sha256';
import encBase64url from 'crypto-js/enc-base64url';
import store from '@/store';
import { getAccessToken, refreshAccessToken } from '../api/keycloak';

function detectCode() {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('code')) {
      if (urlParams.get('state') === localStorage.getItem('state')) {
        localStorage.setItem('code', urlParams.get('code'));
        return urlParams.get('code');
      }
    }
    return null;
  } catch {
    return null;
  }
}

function randomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function authUser() {
  window.localStorage.clear();
  const verifier = randomString(128);
  const stateId = randomString(9);
  const challenge = encBase64url.stringify(sha256(verifier));
  localStorage.setItem('state', stateId);
  localStorage.setItem('verifier', verifier);
  window.location.href = `${process.env.VUE_APP_KEYCLOAK_URL}/protocol/openid-connect/auth?response_type=code&code_challenge=${challenge}&code_challenge_method=S256&client_id=nsoft-hub&state=${stateId}&scope=openid&redirect_uri=${window.location.origin}${process.env.VUE_APP_KEYCLOAK_REDIRECT_PATH}`;
}

export async function validateToken(jwt) {
  const {
    azp,
    iss,
    exp,
  } = jwtDecode(jwt);
  if (azp === 'nsoft-hub' && iss === process.env.VUE_APP_KEYCLOAK_URL) {
    // If access token has expired or is close to expiring (20 sec.) try to refresh token
    if (exp * 1000 >= Date.now() + 20000) {
      // Set next refresh 15 seconds before access token expires
      setTimeout(() => {
        validateToken(jwt);
      }, exp * 1000 - Date.now() - 15000);
      return jwt;
    }
    try {
      const refreshToken = localStorage.getItem('refresh_token');
      const response = await refreshAccessToken(refreshToken);
      const newJwt = response.data.access_token;
      localStorage.setItem('jwt', newJwt);
      localStorage.setItem('refresh_token', response.data.refresh_token);
      localStorage.setItem('idtoken', response.data.id_token);
      const newExp = jwtDecode(newJwt).exp;
      // Set next refresh 15 seconds before access token expires
      setTimeout(() => {
        validateToken(newJwt);
      }, newExp * 1000 - Date.now() - 15000);
      return newJwt;
    } catch (e) {
      // Redirect to KeyCloak if refresh token expired
      authUser();
    }
  }
  return null;
}

export function logout() {
  const idtoken = localStorage.getItem('idtoken');
  store.commit('auth/SET_USER', null);
  window.localStorage.clear();
  window.location.href = `${process.env.VUE_APP_KEYCLOAK_URL}/protocol/openid-connect/logout?response_type=token&post_logout_redirect_uri=${window.location.origin}${process.env.VUE_APP_KEYCLOAK_REDIRECT_PATH}&id_token_hint=${idtoken}`;
}

async function authCodeFlow() {
  if (detectCode()) {
    const code = localStorage.getItem('code');
    const verifier = localStorage.getItem('verifier');
    try {
      const response = await getAccessToken(verifier, code);
      const jwt = response.data.access_token;
      localStorage.setItem('jwt', jwt);
      localStorage.setItem('idtoken', response.data.id_token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
      if (await validateToken(jwt)) {
        store.dispatch('auth/initAuth', jwt)
          .catch((e) => {
            authUser();
            throw (e);
          });
        return;
      }
    } catch (e) {
      authUser();
    }
  } else if (localStorage.getItem('jwt')) {
    try {
      const jwt = await validateToken(localStorage.getItem('jwt'));
      if (jwt) {
        store.dispatch('auth/initAuth', jwt)
          .catch((e) => {
            authUser();
            throw (e);
          });
        return;
      }
    } catch (e) {
      authUser();
    }
  }
  authUser();
}

export default function main() {
  authCodeFlow();
}
