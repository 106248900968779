import { getItems, getItemsById } from '../../../api/index';
import rootTypes from '../../mutationTypes';
import types from './mutationTypes';

export default {
  async getApis({ commit }) {
    commit(types.SET_LOADING, false);
    commit(types.SET_APIS, await getItems('apis'));
    commit(types.SET_LOADING, true);
  },
  async getApiById({ commit }, id) {
    const currentApi = await getItemsById('apis', id);
    commit(types.SET_CURRENT_API, currentApi);
    commit(rootTypes.SET_ASSETS, 'all', { root: true });
  },
  updateApiInApiList({ state, commit }, api) {
    const apiList = state.apis;
    const match = apiList.find((p) => p._id === api._id);
    if (JSON.stringify(match) !== JSON.stringify(api)) {
      apiList[apiList.indexOf(match)] = api;
      commit(types.SET_APIS, []);
      commit(types.SET_APIS, apiList);
    }
  },
  resetCurrentApi({ commit }) {
    commit(types.SET_CURRENT_API, {});
  },
};
