import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'hub',
      redirect: '/products',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/products',
      name: 'products',
      component: () => import('@/pages/Products'),
      children: [
        {
          path: ':id',
          name: 'product',
          component: () => (store.getters.editMode ? import('@/components/products/edit/ProductWrapper')
            : import('@/components/products/view/ProductWrapper')),
          props: true,
          meta: {
            requiresAuth: true,
          },
          beforeEnter: (to, from, next) => {
            if (to.params.id === 'create' && !store.getters.editMode) {
              next('/products');
            } else {
              next();
            }
          },
        }],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/apis',
      name: 'apis',
      component: () => import('@/pages/Apis'),
      children: [{
        path: ':id',
        name: 'api',
        component: () => (store.getters.editMode ? import('@/components/apis/edit/ApiWrapper')
          : import('@/components/apis/view/ApiWrapper')),
        props: true,
        meta: {
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          if (to.params.id === 'create' && !store.getters.editMode) {
            next('/apis');
          } else {
            next();
          }
        },
      }],
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && store.getters['auth/loggedIn']) {
    next();
  } else if (to.path === '/callback') {
    next({ path: '/' });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (from.path.match(/^\/(?:products|apis)\//) && store.getters.editMode && store.getters.unsavedChanges) {
    // eslint-disable-next-line
    const answer = window.confirm('Are you sure you want to leave? Unsaved changes will be lost.');
    if (answer) {
      store.dispatch('setUnsavedChanges', false);
      next();
    } else {
      next(false);
    }
  } else {
    next();
  }
});

export default router;
