import keycloak from '../../../api/keycloak';
import types from './mutationTypes';

export default {
  async initAuth({ commit }, payload) {
    commit(types.SET_LOADING, true);
    try {
      const response = await keycloak.userInfo(payload);
      commit(types.SET_USER, response.data);
      commit(types.SET_LOADING, false);
    } catch (e) {
      commit(types.SET_USER, null);
      throw e;
    }
  },
};
