import types from './mutationTypes';

export default {
  [types.SET_APIS](state, payload) {
    state.apis = payload;
  },
  [types.SET_CURRENT_API](state, payload) {
    state.api = payload;
  },
  [types.SET_LOADING](state, payload) {
    state.loading = payload;
  },
};
